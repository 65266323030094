import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Edit, Save, Trash2, X } from 'lucide-react';
import '../../styles/components/exercise/WeightHistoryItem.scss';

const WeightHistoryItem = ({ entry, onUpdate, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedEntries, setEditedEntries] = useState(entry.entries);

  const handleEdit = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setIsExpanded(true);
  };

  const handleSave = () => {
    onUpdate({ ...entry, entries: editedEntries });
    setIsEditing(false);
  };

  const handleCancel = () => {
    // setEditedEntries(JSON.parse(entry.entries));
    setIsEditing(false);
  };

  const handleEntryChange = (index, field, value) => {
    const updatedEntries = [...editedEntries];
    updatedEntries[index] = { ...updatedEntries[index], [field]: value };
    setEditedEntries(updatedEntries);
  };

  return (
    <div className="weight-history-item">
      <div className="weight-history-header">
        <div className="day-toggle" onClick={() => setIsExpanded(!isExpanded)}>
          <span>Day {entry.day}</span>
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </div>
        <div className="weight-history-actions">
          {!isEditing && (
            <>
              <button onClick={handleEdit}><Edit size={16} /></button>
              <button onClick={() => onDelete(entry.id)}><Trash2 size={16} /></button>
            </>
          )}
          {isEditing && (
            <>
              <button onClick={handleSave}><Save size={16} /></button>
              <button onClick={handleCancel}><X size={16} /></button>
            </>
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="weight-history-details">
          {editedEntries.map((e, index) => (
            <div key={index} className="weight-entry">
              {isEditing ? (
                <>
                  <input
                    type="text"
                    value={e.weight}
                    onChange={(event) => handleEntryChange(index, 'weight', event.target.value)}
                  />
                  <input
                    type="number"
                    value={e.sets}
                    onChange={(event) => handleEntryChange(index, 'sets', parseInt(event.target.value))}
                  />
                  <input
                    type="number"
                    value={e.reps}
                    onChange={(event) => handleEntryChange(index, 'reps', parseInt(event.target.value))}
                  />
                </>
              ) : (
                <>
                  <span>{index+1} - {e.weight}kg x {e.sets}{e.sets > 1 ? 'sets' : 'set'} x {e.reps}{e.reps > 1 ? 'reps' : 'rep'}</span>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WeightHistoryItem;