import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import SortableExerciseItem from './SortableExerciseItem';
import ExerciseForm from './ExerciseForm';
import { updateExercise, updateExercisesOrder, deleteExercise, addWeightHistory, updateWeightHistory, deleteWeightHistory } from '../../api/gymApi';
import '../../styles/components/exercise/RoutineDetail.scss';


const RoutineDetail = ({ routine, onUpdateRoutine, setRoutine }) => {
  const navigate = useNavigate();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');

  useEffect(() => {
    if (routine) setEditedTitle(routine.name);
  }, [routine])

  const handleTitleEdit = () => {
    setIsEditingTitle(true);
  };

  const handleTitleSave = async () => {
    if (editedTitle.trim()) {
      await onUpdateRoutine(routine.id, { name: editedTitle });
      setIsEditingTitle(false);
    }
  };

  const handleExerciseUpdate = async (updatedExercise) => {
    const updatedExerciseResponse = await updateExercise(updatedExercise.id, updatedExercise);
    setRoutine({
      ...routine,
      exercises: routine.exercises.map(exercise => exercise.id === updatedExercise.id ? {...updatedExerciseResponse, weightHistories: updatedExercise.weightHistories} : exercise)
    });
  };
  
  const handleExerciseDelete = (exerciseId) => {
    // TODO: make something nicer
    if (window.confirm('Are you sure you want to delete this exercise?')) {
      deleteExercise(exerciseId);
      setRoutine({
        ...routine,
        exercises: routine.exercises.filter(exercise => exercise.id !== exerciseId)
      });
    }
  };

  const onUpdateWeightEntry = async (exerciseId, entry) => {
    // Create a sanitized copy of entry
    const sanitizedEntry = {
      id: entry.id,
      day: entry.day,
      entries: entry.entries
    };
    await updateWeightHistory(entry.id, sanitizedEntry);
    setRoutine({
      ...routine,
      exercises: routine.exercises.map(exercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            weightHistories: exercise.weightHistories.map(history => history.id === entry.id ? sanitizedEntry : history)
          };
        }
        return exercise;
      })
    });
  }

  const onAddWeightEntry = async (exercise, entry) => {
    try { 
      const updatedExercise = await addWeightHistory({exerciseId: exercise.id, day: entry.day, entries: entry.entries});
      setRoutine(prevRoutine => ({
        ...prevRoutine,
        exercises: prevRoutine.exercises.map(ex => 
          ex.id === exercise.id ? updatedExercise : ex
        )
      }));
    } catch (error) {
      console.error('Failed to add weight entries:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const onDeleteWeightEntry = async (exerciseId, entryId) => {
    await deleteWeightHistory(entryId);
    setRoutine({
      ...routine,
      exercises: routine.exercises.map(exercise => {
        if (exercise.id === exerciseId) {
          return {
            ...exercise,
            weightHistories: exercise.weightHistories.filter(history => history.id !== entryId)
          };
        }
        return exercise;
      })
    });
  }

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150, // Adjust the delay as needed
        tolerance: 5, // Adjust the tolerance as needed
      },
    }),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (!active || !over || active.id === over.id) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = routine.exercises.findIndex(
        (exercise) => exercise.id === active.id
      );
      const newIndex = routine.exercises.findIndex(
        (exercise) => exercise.id === over.id
      );

      const updatedExercises = arrayMove(
        routine.exercises,
        oldIndex,
        newIndex
      );

      // Update the position field for each exercise
      const exercisesWithUpdatedOrder = updatedExercises.map(
        (exercise, index) => ({
          ...exercise,
          position: index,
        })
      );

        // Update the routine state
        setRoutine({
          ...routine,
          exercises: exercisesWithUpdatedOrder,
        });

      // Save the new position to the database
      try {
        await updateExercisesOrder(routine.id, exercisesWithUpdatedOrder.map(exercise => {return {id: exercise.id, position: exercise.position }}));
      } catch (error) {
        console.error('Failed to update exercise position:', error);
        // Handle error (e.g., revert state, show error message)
        const updatedExercises = arrayMove(
          routine.exercises,
          oldIndex,
          newIndex
        );
  
        // Update the position field for each exercise
        const exercisesWithUpdatedOrder = updatedExercises.map(
          (exercise, index) => ({
            ...exercise,
            position: index,
          })
        );

        // Update the routine state
        setRoutine({
          ...routine,
          exercises: exercisesWithUpdatedOrder,
        });
      }
    }
  };

  if (!routine) return <div>Routine not found</div>;

  return (
    <>
      <h1>Routine detail</h1>
      <div className="routine-detail">
        <button className="back-button" onClick={() => navigate('/exercise')}>Back to Routines</button>
        {isEditingTitle ? (
          <h2 className="edit-title">
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />
            <button onClick={handleTitleSave}>Save</button>
          </h2>
        ) : (
          <h2>{routine.name} <button onClick={handleTitleEdit}>Edit title</button></h2>
        )}
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={routine.exercises.map((exercise) => exercise.id)}
            strategy={verticalListSortingStrategy}
          >
            {routine && routine.exercises.map((exercise) => (
              <SortableExerciseItem
                key={exercise.id}
                exercise={exercise}
                onUpdate={handleExerciseUpdate}
                onDelete={handleExerciseDelete}
                onUpdateWeightEntry={onUpdateWeightEntry}
                onDeleteWeightEntry={onDeleteWeightEntry}
                onAddWeightEntry={onAddWeightEntry}
              />
            ))}
          </SortableContext>
        </DndContext>
        <ExerciseForm routine={routine} setRoutine={setRoutine} isEdit={false} />
      </div>
    </>
  );
};

export default RoutineDetail;