import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import WeeklyRoutine from '../components/exercise/WeeklyRoutine';
import Routine from '../components/exercise/Routine';
import { fetchRoutines, createRoutine, updateRoutine } from '../api/gymApi';
import '../styles/pages/ExercisePage.scss';

const ExercisePage = () => {
  // TODO: move to StateContext so I can use it in other components
  const [routines, setRoutines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: move to hook?
    const loadRoutines = async () => {
      try {
        const data = await fetchRoutines();
        setRoutines(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Failed to load routines:', error);
        // Handle error (e.g., show error message to user)
      }
    };

    loadRoutines();
  }, []);


  const handleAddRoutine = async (dayIndex) => {
    try {
      navigate(`/exercise/routine/new`);
    } catch (error) {
      console.error('Failed to add routine:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleUpdateRoutine = async (routineId, updatedData) => {
    try {
      const updatedRoutine = await updateRoutine(routineId, updatedData);
      setRoutines(prevRoutines => prevRoutines.map(r => r.id === routineId ? updatedRoutine : r));
    } catch (error) {
      console.error('Failed to update routine:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleCreateRoutine = async (data) => {
    try {
      const createdRoutine = await createRoutine(data);
      setRoutines([...routines, createdRoutine]);
    } catch (error) {
      console.error('Failed to update routine:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleDeleteExercise = async (routineId, exerciseId) => {
    try {
      const routineToUpdate = routines.find(r => r.id === routineId);
      const updatedExercises = routineToUpdate.exercises.filter(e => e.id !== exerciseId);
      const updatedRoutine = await updateRoutine(routineId, { exercises: updatedExercises });
      setRoutines(prevRoutines => prevRoutines.map(r => r.id === routineId ? updatedRoutine : r));
    } catch (error) {
      console.error('Failed to delete exercise:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  if (isLoading) return <div className="exercise-page">Loading...</div>;
  if (!routines) return <div className="exercise-page">Error loading routines</div>;

  return (
    <div className="exercise-page">
      <Routes>
        <Route path="/" element={
          <>
            <WeeklyRoutine routines={routines} />
            <div className="exercise-page__add-routine">
              <button className="exercise-page__add-routine__button" onClick={handleAddRoutine}>Add New Routine</button>
            </div>
          </>
        } />
        <Route path="/routine/:routineId" element={
          <Routine 
              routines={routines}
              onUpdateRoutine={handleUpdateRoutine}
              onCreateRoutine={handleCreateRoutine}
              onDeleteExercise={handleDeleteExercise}
          />}
        />
      </Routes>
    </div>
  );
};

export default ExercisePage;