// src/api/gymApi.js
import { API_BASE_URL } from '../config';
import api from './axios';

const ROUTINES_URL = `${API_BASE_URL}/routines/`;
const EXERCISES_URL = `${API_BASE_URL}/exercises/`;
const WEIGHT_HISTORIES_URL = `${API_BASE_URL}/weightHistories/`;

// Fetch all routines
export const fetchRoutines = async () => {
  try {
    // TODO: get userId
    const response = await api.get(`${ROUTINES_URL}byUser/1`);
    return response.data;
  } catch (error) {
    console.error('Error fetching routines:', error);
    throw error;
  }
};

// Fetch a single routine by ID
export const fetchRoutine = async (id) => {
  try {
    const response = await api.get(`${ROUTINES_URL}${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching routine ${id}:`, error);
    throw error;
  }
};

// Create a new routine
export const createRoutine = async (routineData) => {
  try {
    const response = await api.post(`${ROUTINES_URL}`, routineData);
    return {...response.data, exercises: []};
  } catch (error) {
    console.error('Error creating routine:', error);
    throw error;
  }
};

// Update an existing routine
export const updateRoutine = async (id, routineData) => {
  try {
    const response = await api.put(`${ROUTINES_URL}${id}`, routineData);
    return response.data;
  } catch (error) {
    console.error(`Error updating routine ${id}:`, error);
    throw error;
  }
};

// Delete a routine
export const deleteRoutine = async (id) => {
  try {
    await api.delete(`${API_BASE_URL}/routines/${id}`);
  } catch (error) {
    console.error(`Error deleting routine ${id}:`, error);
    throw error;
  }
};

// Add an exercise to a routine
export const addExercise = async (exerciseData) => {
  try {
    const response = await api.post(`${EXERCISES_URL}`, exerciseData);
    return response.data;
  } catch (error) {
    console.error(`Error updating exercise in routine:`, error);
    throw error;
  }
};

// Update an exercise in a routine
export const updateExercise = async (exerciseId, exerciseData) => {
  try {
    const response = await api.put(`${EXERCISES_URL}${exerciseId}`, exerciseData);
    return response.data;
  } catch (error) {
    console.error(`Error updating exercise in routine ${exerciseId}:`, error);
    throw error;
  }
};

// Update the position of all the exercises for a routine
export const updateExercisesOrder = async (routineId, exercises) => {
  try {
    const response = await api.put(`${ROUTINES_URL}${routineId}/exercises/position`, { exercises });
    return response.data;
  } catch (error) {
    console.error(`Error updating exercises position in routine ${routineId}:`, error);
    throw error;
  }
}

// Delete an exercise from a routine
export const deleteExercise = async (exerciseId) => {
  try {
    await api.delete(`${API_BASE_URL}/exercises/${exerciseId}`);
  } catch (error) {
    console.error(`Error deleting exercise in routine ${exerciseId}:`, error);
    throw error;
  }
};

export const addWeightHistory = async (weightData) => {
  try {
    const response = await api.post(`${WEIGHT_HISTORIES_URL}`, weightData);
    return response.data;
  } catch (error) {
    console.error(`Error adding weight history:`, error);
    throw error;
  }
};

export const updateWeightHistory = async (weightHistoryId, weightData) => {
  try {
    const response = await api.put(`${WEIGHT_HISTORIES_URL}${weightHistoryId}`, weightData);
    return response.data;
  } catch (error) {
    console.error(`Error updating weight history:`, error);
    throw error;
  }
};

export const deleteWeightHistory = async (id) => {
  try {
    await api.delete(`${WEIGHT_HISTORIES_URL}${id}`);
  } catch (error) {
    console.error(`Error deleting weight history:`, error);
    throw error;
  }
};

// Add a workout log to an exercise
export const addWorkoutLog = async (routineId, exerciseIndex, logData) => {
  try {
    const routine = await fetchRoutine(routineId);
    if (!routine.exercises[exerciseIndex].logs) {
      routine.exercises[exerciseIndex].logs = [];
    }
    routine.exercises[exerciseIndex].logs.push(logData);
    const response = await updateRoutine(routineId, routine);
    return response;
  } catch (error) {
    console.error(`Error adding workout log to exercise in routine ${routineId}:`, error);
    throw error;
  }
};