import React, { useState, useEffect, useCallback } from "react";
import WeightHistoryItem from "./WeightHistoryItem";

const WeightHistory = ({ exercise, onAddWeightEntry, onUpdateWeightEntry, onDeleteWeightEntry }) => {
  // Default values for sets and reps
  const DEFAULT_SETS = exercise.sets;
  const DEFAULT_REPS = exercise.reps;

  const [currentDay, setCurrentDay] = useState(exercise.weightHistories.length + 1);

  useEffect(() => {
    setCurrentDay(exercise.weightHistories.length + 1);
  }, [exercise.weightHistories.length]);

  const createEmptyState = useCallback(() => ({
    day: currentDay,
    weight: '',
    sets: DEFAULT_SETS,
    reps: DEFAULT_REPS
  }), [currentDay, DEFAULT_SETS, DEFAULT_REPS]);

  const [newWeightEntries, setNewWeightEntries] = useState([createEmptyState()]);

  useEffect(() => {
    setNewWeightEntries([createEmptyState()]);
  }, [currentDay, createEmptyState]);

  const handleEntryChange = (index, field, value) => {
    const updatedEntries = [...newWeightEntries];
    const updatedEntry = { ...updatedEntries[index], [field]: value };
    updatedEntries[index] = updatedEntry;
    setNewWeightEntries(updatedEntries);
  };

  const addNewEntryGroup = () => {
    setNewWeightEntries([...newWeightEntries, createEmptyState()]);
  };

  const removeEntryGroup = (index) => {
    const updatedEntries = newWeightEntries.filter((_, i) => i !== index);
    setNewWeightEntries(updatedEntries);
  };

  const handleAddWeightEntries = async () => {
    const validEntries = newWeightEntries.filter(entry =>
      entry.weight && entry.sets && entry.reps
    );
    if (validEntries.length > 0) {
      await onAddWeightEntry(exercise, {
        day: currentDay,
        entries: validEntries
      });
      setCurrentDay(currentDay + 1);
    } else {
      // TODO: show error message - all fields are required
      alert("All fields are required");
    }
  };

  return (
    <div className="weight-history">
      {exercise.weightHistories && exercise.weightHistories.length > 0 && <h4>Weight History</h4> }
      {exercise.weightHistories && exercise.weightHistories.map((entry) => (
        <WeightHistoryItem
          key={entry.id}
          entry={entry}
          onUpdate={(updatedEntry) => onUpdateWeightEntry(exercise.id, updatedEntry)}
          onDelete={(day) => onDeleteWeightEntry(exercise.id, day)}
        />
      ))}
      <div className="add-weight-entry">
        <h3>Add New Entry for Day {currentDay}</h3>
        {newWeightEntries.map((entry, index) => (
          <div key={index} className="weight-entry-group">
            <div style={{"display": "flex"}}>
              <div style={{"display": "flex"}}>
                <div className="input-group">
                  <label htmlFor={`weight-${exercise.id}-${index}`}>Weight</label>
                  <input
                    id={`weight-${exercise.id}-${index}`}
                    type="text"
                    placeholder="50"
                    value={entry.weight}
                    onChange={(e) => handleEntryChange(index, 'weight', e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor={`sets-${exercise.id}-${index}`}>Sets</label>
                  <input
                    id={`sets-${exercise.id}-${index}`}
                    type="number"
                    placeholder="2"
                    value={entry.sets}
                    onChange={(e) => handleEntryChange(index, 'sets', e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor={`reps-${exercise.id}-${index}`}>Reps</label>
                  <input
                    id={`reps-${exercise.id}-${index}`}
                    type="number"
                    placeholder="10"
                    value={entry.reps}
                    onChange={(e) => handleEntryChange(index, 'reps', e.target.value)}
                  />
                </div>
              </div>
              { newWeightEntries.length > 1 && (
                <div>
                  <label>Actions</label>
                  <div>
                    <button className="remove-entry-button" style={{"margin":"5px"}} onClick={() => removeEntryGroup(index)}>-</button>
                  </div>
                </div>
              )}
            </div>
            <div>
              {index === newWeightEntries.length - 1 && (
                <button className="add-entry-button" style={{"margin":"5px"}} onClick={addNewEntryGroup}>Add new entry</button>
              )}
            </div>
          </div>
        ))}
        <button className="save-entries-button" onClick={handleAddWeightEntries}>Save Entries</button>
      </div>
    </div>
  );
};

export default WeightHistory;
