
import React, { useState } from 'react';
import { addExercise } from '../../api/gymApi';

const ExerciseForm = ({ routine, setRoutine, isEdit, existingExercise, onUpdate = () => {}, onCancel = () => {}  }) => {
  const emptyStateExercise = { name: '', sets: '', reps: '', description: '', weightHistories: [] };
  const [exercise, setExercise] = useState(isEdit ? existingExercise : emptyStateExercise);

  const handleAddExercise = () => {
    if (exercise.name && exercise.sets && exercise.reps) {
      addExercise({routineId: routine.id, ...exercise, position: routine.exercises.length})
        .then((exercise) => {
          setRoutine({
            ...routine,
            exercises: [...routine.exercises, {...exercise, weightHistories: []}]
          });
          setExercise(emptyStateExercise);
        });
    }
  };

  return (
    <div className="add-exercise">
      <h3>{ isEdit ? "Edit Exercise" : "Add New Exercise" }</h3>
        <div className="exercise-form">
          <div className="form-row">
            <div className="form-group name-group">
              <label htmlFor="exercise-name">Exercise Name:</label>
              <input
                id="exercise-name"
                type="text"
                placeholder="e.g., Squats"
                value={exercise.name}
                onChange={(e) => setExercise({...exercise, name: e.target.value})}
              />
            </div>
            <div className="form-group sets-group">
              <label htmlFor="exercise-sets">Sets:</label>
              <input
                id="exercise-sets"
                type="number"
                placeholder="e.g., 3"
                value={exercise.sets}
                onChange={(e) => setExercise({...exercise, sets: e.target.value})}
              />
            </div>
            <div className="form-group reps-group">
              <label htmlFor="exercise-reps">Reps:</label>
              <input
                id="exercise-reps"
                type="number"
                placeholder="e.g., 10"
                value={exercise.reps}
                onChange={(e) => setExercise({...exercise, reps: e.target.value})}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="exercise-description">Description (optional):</label>
            <textarea
              id="exercise-description"
              placeholder="Additional notes or instructions"
              value={exercise.description}
              onChange={(e) => setExercise({...exercise, description: e.target.value})}
            />
          </div>
          { isEdit ? 
            <div className="edit-actions">
              <button className="cancel-button" onClick={onCancel}>Cancel</button>
              <button className="save-button" onClick={() => onUpdate(exercise)}>Save</button>
            </div> : 
            <button onClick={handleAddExercise}>Add Exercise</button>
          }
        </div>
    </div>
  );
}

export default ExerciseForm;