import { API_BASE_URL } from '../config';
import api from '../api/axios';

export const createProfile = async (data) => {
  return api.post(`${API_BASE_URL}/profile/`, data)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateProfile = async (data) => {
  return api.put(`${API_BASE_URL}/users/1`, data)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getProfile = async (id) => {
  return api.get(`${API_BASE_URL}/profile/1`)
};