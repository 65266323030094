import { Edit, Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import { GripVertical } from 'lucide-react';

const ExerciseInfo = ({ exercise, onEdit, onDelete, showHistory, setShowHistory, dragHandleProps }) =>  {

  const exerciseMaxWeight = exercise.weightHistories?.reduce((max, entry) => {
    const maxEntry = entry.entries.reduce((max, e) => {
      const weight = parseInt(e.weight);
      return weight > max ? weight : max;
    }, 0);
    return maxEntry > max ? maxEntry : max;
  }, 0);

  return (
   <>
      <div className="exercise-header">
        <h3>{exercise.name}</h3>
        <div className="drag-handle" {...dragHandleProps}>
          <GripVertical size={16} />
        </div>
      </div>
     <p>{exercise.sets} sets x {exercise.reps} reps</p>
     {exercise.description && <p className="description">{exercise.description}</p>}
     <p>Max Weight: {exerciseMaxWeight ? exerciseMaxWeight + 'kg' : 'N/A'}</p>
     <div className="exercise-actions">
       <button onClick={() => onEdit(true)}><Edit size={16} /> Edit</button>
       <button onClick={() => onDelete(exercise.id)}><Trash2 size={16} /> Delete</button>
       <button onClick={() => setShowHistory(!showHistory)}>
         {showHistory ? <ChevronUp size={16} /> : <ChevronDown size={16} />} Weight History
       </button>
     </div>
   </>
  );
}
  

export default ExerciseInfo;