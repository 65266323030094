import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, X, Home, User, Dumbbell, Apple } from 'lucide-react';
import ThemeToggle from '../ThemeToggle';
import profilePicture from '../../assets/images/profile-photo.jpeg';
import '../../styles/components/NavigationMenu.scss';

const NavigationMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const menuItems = [
    { to: "/", icon: <Home size={20} />, text: "Home" },
    { to: "/profile", icon: <User size={20} />, text: "Profile" },
    { to: "/exercise", icon: <Dumbbell size={20} />, text: "Exercise" },
    { to: "/nutrition", icon: <Apple size={20} />, text: "Nutrition" },
  ];

  return (
    <>
      <div className="mobile-header">
        <button className="menu-toggle" onClick={toggleMenu}>
          <Menu size={24} />
        </button>
        <NavLink to="/" className="nav-logo">
          <img src={profilePicture} alt='Logo' />
        </NavLink>
        <ThemeToggle />
      </div>
      <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <div className="nav-header">
          <NavLink to="/" className="nav-logo">
            <img src={profilePicture} alt='Logo' />
          </NavLink>
          <button className="menu-toggle" onClick={toggleMenu}>
            <X size={24} />
          </button>
        </div>
        <ul className="nav-links">
          {menuItems.map((item) => (
            <li key={item.to}>
              <NavLink to={item.to} onClick={() => setIsOpen(false)}>
                {item.icon}
                <span>{item.text}</span>
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="nav-footer">
          <ThemeToggle />
        </div>
      </nav>
    </>
  );
};

export default NavigationMenu;