import React from 'react';
import NavigationMenu from './NavigationMenu';

const Layout = ({ children }) => (
  <div className="app">
    <NavigationMenu />
    <main className="main-content">
      {children}
    </main>
  </div>
);

export default Layout;