// src/components/ExerciseItem.js
import React, { useState } from 'react';

import '../../styles/components/exercise/ExerciseItem.scss';
import ExerciseForm from './ExerciseForm';
import ExerciseInfo from './ExerciseInfo';
import WeightHistory from './WeightHistory';

const ExerciseItem = ({ exercise, onUpdate, onDelete, onAddWeightEntry, onUpdateWeightEntry, onDeleteWeightEntry, dragHandleProps, isDragging, }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const handleOnExerciseUpdate = (updatedExercise) => {
    onUpdate(updatedExercise);
    setIsEditing(false);
  }

  return (
    <div className={`exercise-item ${isDragging ? 'dragging' : ''}`}>
      <div className="content">
        {isEditing ? (
          <ExerciseForm isEdit={true} existingExercise={exercise} onUpdate={handleOnExerciseUpdate} onCancel={() => {setIsEditing(false)}} />
        ) : (
          <ExerciseInfo exercise={exercise} onEdit={() => setIsEditing(true)} onDelete={onDelete} showHistory={showHistory} setShowHistory={setShowHistory} dragHandleProps={dragHandleProps} />
        )}
        {showHistory && (
          <WeightHistory exercise={exercise} onAddWeightEntry={onAddWeightEntry} onUpdateWeightEntry={onUpdateWeightEntry} onDeleteWeightEntry={onDeleteWeightEntry} />
        )}
      </div>
    </div>
  );
}

export default ExerciseItem;