import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const RoutineCreate = ({ routines, onCreateRoutine }) => {
  const navigate = useNavigate();
  const [editedTitle, setEditedTitle] = useState('');
  const [dayOfWeek, setDayOfWeek] = useState(0);
  const [routine] = useState(null);

  // INFO: new routine
  const createRoutine = async () => {
    const data = {
      name: editedTitle,
      dayOfWeek: dayOfWeek
    }
    onCreateRoutine(data);
  }

  const getAvailableDays = () => {
    const allDays = [
      {label: 'Monday', value: 1},
      {label: 'Tuesday', value: 2},
      {label: 'Wednesday', value: 3},
      {label: 'Thursday', value: 4},
      {label: 'Friday', value: 5},
      {label: 'Saturday', value: 6},
      {label: 'Sunday', value: 7}
    ]
    return [{label: 'Select Day', value: 0}, ...allDays.filter(day => {
      return !routines.some(routine => routine.dayOfWeek === day.value)
    })]
  }

  const handleSave = async () => {
    try {
      await createRoutine(routine);
      navigate('/exercise'); // Redirect to exercise page after saving
    } catch (error) {
      console.error("Error saving routine:", error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <>
      <h1>Create routine</h1>
      <div className="routine-detail">
        <button className="back-button" onClick={() => navigate('/exercise')}>Back to Routines</button>
        <h2>Create New Routine</h2>
        <div className="routine-detail__create">
          <input
            type="text"
            name="name"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            placeholder="Routine Name"
          />
          <div className="custom-select">
            <select
              name="dayOfWeek"
              value={dayOfWeek}
              onChange={(e) => setDayOfWeek(e.target.value)}
            >
              {getAvailableDays().map((day) => (
                <option key={day.value} value={day.value}>{day.label}</option>
              ))}
            </select>
          </div>
        </div>
        <button className={`routine-detail__create-button ${!editedTitle || !dayOfWeek ? "disabled" : ""}`} disabled={!editedTitle || !dayOfWeek} onClick={handleSave}>Save Routine</button>
      </div>
    </>
  );
};

export default RoutineCreate;