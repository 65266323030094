import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StateProvider } from './contexts/StateContext';
import { ThemeProvider } from './contexts/ThemeContext';
import Layout from './components/layout/Layout';
import AppRoutes from './routes/AppRoutes';
import './styles/index.scss';

function App() {
  return (
    <StateProvider>
      <ThemeProvider>
        <Router>
          <Layout>
            <AppRoutes />
          </Layout>
        </Router>
      </ThemeProvider>
    </StateProvider>
  );
}

export default App;