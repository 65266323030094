import React from 'react';
import { Link } from 'react-router-dom';
import { Activity, Utensils, TrendingUp, Award } from 'lucide-react';
import '../styles/pages/HomePage.scss';

const HomePage = () => {
  return (
    <div className="home-page">
      <header className="hero">
        <h1>Track Your Health Journey</h1>
        <p>Exercise. Nutrition. Progress. All in one place.</p>
        <Link to="/signup" className="cta-button">Get Started</Link>
      </header>

      <section className="features">
        <div className="feature-card">
          <Activity size={48} />
          <h2>Track Your Workouts</h2>
          <p>Log your exercises, sets, reps, and see your progress over time.</p>
        </div>
        <div className="feature-card">
          <Utensils size={48} />
          <h2>Monitor Your Diet</h2>
          <p>Record your meals and get personalized nutrition insights.</p>
        </div>
        <div className="feature-card">
          <TrendingUp size={48} />
          <h2>Visualize Progress</h2>
          <p>See your fitness journey with detailed stats and charts.</p>
        </div>
        <div className="feature-card">
          <Award size={48} />
          <h2>Achieve Your Goals</h2>
          <p>Set targets and get recommendations to reach your fitness objectives.</p>
        </div>
      </section>

      <section className="how-it-works">
        <h2>How It Works</h2>
        <div className="steps">
          <div className="step">
            <div className="step-number">1</div>
            <h3>Log Your Activities</h3>
            <p>Record your workouts and meals daily.</p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <h3>Get Insights</h3>
            <p>Receive personalized analytics and recommendations.</p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <h3>Improve</h3>
            <p>Adjust your routine based on data-driven insights.</p>
          </div>
        </div>
      </section>

      <section className="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonial-container">
          <div className="testimonial">
            <p>"This app has transformed my fitness routine. I've never been so consistent!"</p>
            - Sarah J.
          </div>
          <div className="testimonial">
            <p>"The nutrition tracking feature helped me understand my eating habits and make better choices."</p>
            - Mike T.
          </div>
        </div>
      </section>

      <section className="cta">
        <h2>Ready to Start Your Journey?</h2>
        <Link to="/signup" className="cta-button">Sign Up Now</Link>
      </section>
    </div>
  );
};

export default HomePage;