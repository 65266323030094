import { createContext, useState } from "react";

const StateContext = createContext();

/**
 * State provider to manage the state of the app.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @return {JSX.Element} Component
 */
export const StateProvider = ({ children }) => {
  const [variableusername, setVariableusername] = useState('State Context');

  const setRandomUsername = () => {
    const randomUsernames = ['Alice', 'Bob', 'Charlie', 'David', 'Eve', 'Frank', 'Grace', 'Heidi']
    const randomIndex = Math.floor(Math.random() * randomUsernames.length);
    setVariableusername(randomUsernames[randomIndex]);
  }

  return (
    <StateContext.Provider value={{
      variableusername,
      setRandomUsername
    }}>
      {children}
    </StateContext.Provider>
  );
}

export default StateContext;