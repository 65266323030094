import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RoutineCreate from './RoutineCreate';
import RoutineDetail from './RoutineDetail';
import '../../styles/components/exercise/RoutineDetail.scss';


const Routine = ({ routines, onUpdateRoutine, onCreateRoutine }) => {
  const { routineId } = useParams();
  const [routine, setRoutine] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [createRoutine, setCreateRoutine] = useState(false);

  useEffect(() => {
    if (routineId === 'new') {
      setCreateRoutine(true);
    } else {
      const foundRoutine = routines.find(r => r.id === parseInt(routineId));
      setIsLoading(false);
      if (foundRoutine) {
        return setRoutine(foundRoutine);
      }
    }
  }, [routines, routineId]);

  if (!createRoutine && isLoading) return <div className="routine-detail">Loading...</div>;
  if (!createRoutine && !isLoading && !routine) return <div className="routine-detail">Error</div>;
  
  return (
    <>
    {
      createRoutine ?
        <RoutineCreate routines={routines} onCreateRoutine={onCreateRoutine} /> :
        <RoutineDetail routine={routine} setRoutine={setRoutine} onUpdateRoutine={onUpdateRoutine} />
    }
    </>
  );
};

export default Routine;