import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/exercise/WeeklyRoutine.scss';

const WeeklyRoutine = ({ routines }) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const getRoutine = (dayIndex) => {
    return routines.find(r => r.dayOfWeek === (dayIndex+1))
  }

  return (
    <>
      <h1>Daily Routines</h1>
      {
        routines.length > 0 ?
        <div className="weekly-routine">
          {days.map((day, indexDay) => (
            <>
              {
                getRoutine(indexDay) ? (
                  <div key={day} className="day-routine">
                    <h3>{day}</h3>
                    <Link to={`/exercise/routine/${getRoutine(indexDay).id}`}>
                      {getRoutine(indexDay).name}
                    </Link>
                  </div>
                ) : null
              }
            </>
          ))
        }
        </div> : <div className="weekly-routine__no-routine">No routines added yet</div>
      }
    </>
  );
};

export default WeeklyRoutine;